// Action Types
const SET_RECOMMENDATIONS = 'SET_RECOMMENDATIONS';
const SET_FILTERS = 'SET_FILTERS';
const CLEAR_FILTERS = 'CLEAR_FILTERS';

// Начальные значения фильтров для разных страниц
export const initFilters = {
    region: null,
    specialist: {
        value: '',
        label: ''
    },
    dateStart: '',
    dateEnd: '',
    statuses: ['rework', 'accepted', 'moderation','acceptedWithEdits'],
    types: ["new", "correction"],
};

export const moderatorInitFilters = {
    ...initFilters,
    statuses: ["deleted", 'rework', 'accepted', 'moderation','acceptedWithEdits','mainEntryDeleted'],
};

const initialState = {
    filters: initFilters,
    recommendations: [],
    filteredRecommendations: [],
    isModerator: false
};

export const recommendationListReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_RECOMMENDATIONS: {
            const newIsModerator = action.source === 'moderator';
            const newState = {
                ...state,
                recommendations: action.payload,
                isModerator: newIsModerator,
                // Устанавливаем начальные фильтры только при первой загрузке
                filters: state.recommendations.length === 0 
                    ? (newIsModerator ? moderatorInitFilters : initFilters)
                    : state.filters
            };
            
            // Применяем текущие фильтры к новым данным
            const filtered = newState.recommendations.filter(item => {
                const regionMatch = !newState.filters.region?.value ||
                    item.region === newState.filters.region.value;
                
                const specialistMatch = !newState.filters.specialist?.value ||
                    item.specialist?._id === newState.filters.specialist.value;
                
                let dateMatch = true;
                if (newState.filters.dateStart || newState.filters.dateEnd) {
                    const itemDate = new Date(item.updatedAt);
                    const itemDateString = itemDate.toISOString().split('T')[0];

                    if (newState.filters.dateStart) {
                        dateMatch = dateMatch && newState.filters.dateStart <= itemDateString;
                    }
                    if (newState.filters.dateEnd) {
                        dateMatch = dateMatch && newState.filters.dateEnd >= itemDateString;
                    }
                }

                const statusMatch = !newState.filters.statuses?.length ||
                    newState.filters.statuses.includes(item.status);

                const typeMatch = !newState.filters.types?.length ||
                    newState.filters.types.includes(item.type);

                return regionMatch && specialistMatch && dateMatch && statusMatch && typeMatch;
            });

            // Применяем фильтрацию по роли
            const roleFiltered = newIsModerator ? filtered : filtered.filter(item => item.status !== 'deleted');

            return {
                ...newState,
                filteredRecommendations: roleFiltered
            };
        }
        case SET_FILTERS:
            const filtered = state.recommendations.filter(item => {
                const regionMatch = !action.value.region?.value ||
                    item.region === action.value.region.value;
                
                const specialistMatch = !action.value.specialist?.value ||
                    item.specialist?._id === action.value.specialist.value;
                
                let dateMatch = true;
                if (action.value.dateStart || action.value.dateEnd) {
                    const itemDate = new Date(item.updatedAt);
                    const itemDateString = itemDate.toISOString().split('T')[0];

                    if (action.value.dateStart) {
                        dateMatch = dateMatch && action.value.dateStart <= itemDateString;
                    }
                    if (action.value.dateEnd) {
                        dateMatch = dateMatch && action.value.dateEnd >= itemDateString;
                    }
                }

                const statusMatch = !action.value.statuses?.length ||
                    action.value.statuses.includes(item.status);

                const typeMatch = !action.value.types?.length ||
                    action.value.types.includes(item.type);

                return regionMatch && specialistMatch && dateMatch && statusMatch && typeMatch;
            });

            return {
                ...state,
                filteredRecommendations: filtered,
                filters: action.value
            };
        case CLEAR_FILTERS:
            return {
                ...state,
                filteredRecommendations: state.isModerator 
                    ? state.recommendations 
                    : state.recommendations.filter(item => item.status !== 'deleted'),
                filters: state.isModerator ? moderatorInitFilters : initFilters
            };
        default:
            return state;
    }
};

// Action Creators
export const setRecommendations = (recommendations, source = '') => ({
    type: SET_RECOMMENDATIONS,
    payload: recommendations,
    source
});

export const setFilters = (filters) => ({
    type: SET_FILTERS,
    value: filters
});

export const clearFilters = () => ({
    type: CLEAR_FILTERS
});

export const selectRecommendationList = (state) => state.recommendationList;
